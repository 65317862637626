jQuery(document).ready(function ($) {
  var toplocation = location.href.split('/');
  $(function () {
    // let catename = new RegExp(`${toplocation[4]}`);
    // console.log('catename :>> ', catename);
    if (toplocation[3] === '') {
      topLoadMovie();
    } //changeページでの処理


    if (toplocation[3] === 'change') {
      $(document).on('wpcf7invalid', function () {
        $.ajax().always(function () {
          $('.wpcf7-form-control-wrap').each(function (index, el) {
            if ($(el).find('.wpcf7-not-valid-tip').length) {
              $(el).find('.wpcf7-not-valid-tip').insertAfter($(el).parent()).show();
            }
          });
        });
      });
      $('.wpcf7-form-control-wrap input').on('change', function () {
        $(this).parents('.contentformArea__formitem--item').find('.wpcf7-not-valid-tip').hide();
      });
      $('.grecaptcha-badge').addClass('dispnone');
      $('#topbtn').hide();
    } else {
      $('.grecaptcha-badge').removeClass('dispnone');
    }

    var urlttt = toplocation[toplocation.length - 2];
    var nums = $('.subcontentArea__infotagarea a').length;

    for (var i = 0; i < nums; i++) {
      var linkname = $('.subcontentArea__infotagarea a').eq(i).attr('href');
      var linknameArg = linkname.split('/');
      var linknameText = linknameArg[linknameArg.length - 2];

      if (linknameText === urlttt) {
        $('.subcontentArea__infotagarea a').eq(i).addClass('active');
        break;
      }
    }

    var hSize = $(window).height();
    $('.home header').height(hSize); // アドレスバーを除いたサイズを付与

    $('.multiple-item').slick({
      infinite: false,
      dots: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 1122,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 741,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
    $('.plan-item').slick({
      infinite: false,
      dots: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 1122,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 741,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    }); // macのクローム以外をsmooth scroll　macのクロームはCSS制御

    var ua = window.navigator.userAgent.toLowerCase();

    if (!(ua.indexOf('mac os x') !== -1 && navigator.userAgent.match('Chrome/'))) {
      $('a[href^="#"]').click(function () {
        var headHeight = $('.headnav').height();
        var speed = 500;
        var href = $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - headHeight;
        $('html, body').animate({
          scrollTop: position
        }, speed, 'swing');
        return false;
      });
    }

    if (ua.indexOf('iPhone') === -1) {
      $('html').addClass('ios');
    } //ページネーションの不要時非表示にする


    var pagenationNum = $('.pagination .page-numbers li').length;

    if (pagenationNum <= 3) {
      $('.pagination').hide();
    } //formのラベルデザイン変更


    $("input[type='radio']:checked").parent('label').addClass('checked');
    $("input[type='radio']").on('change', function () {
      $(this).parents('.kuwake').find('label').removeClass('checked');
      $(this).parents('label').addClass('checked');
    });
    $('.wpcf7-list-item input[type="radio"]').after('<span class="outside"><span class="inside"></span></span>');
    $('.wpcf7-list-item input[type="checkbox"]').after('<span class="checkmark"></span>');
    $('.check__privacy').on('change', function () {
      if ($(this).parents('.formarea__confirm--item').find('label').hasClass('checked')) {
        $(this).parents('.formarea__confirm--item').find('label').removeClass('checked');
      } else {
        $(this).parents('label').addClass('checked');
      }
    }); //スマホのハンバーガーメニュー操作

    var modalbk = $('.modal_bk');
    $('.btn-trigger').on('click', function () {
      var nav = document.querySelector('.headnav__nav');

      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).prev('.headnav__nav').removeClass('active');
        modalbk.css('z-index', '-100');
        scroll_gogo();
      } else {
        $(this).addClass('active');
        $(this).prev('.headnav__nav').addClass('active');
        modalbk.css('z-index', '998');
        none_scroll();
      }

      return false;
    });
    modalbk.on('click', function () {
      modalbk.css('z-index', '-100');
      $('.btn-trigger').removeClass('active');
      $('.headnav__nav').removeClass('active');
      scroll_gogo();
      return false;
    }); //料金プランのタブ操作

    $('#splinks .subcontent__tabitems--item').on('click', function () {
      var ww = $(window).width();
      var count = $(this).index();

      if (ww < 1122) {
        var speed = 500;
        var headHeight = $('.headnav').height();
        var target = $('#splinks');
        var position = target.offset().top - (headHeight + 8);
        $('html,body').animate({
          scrollTop: position
        }, speed, 'swing');
      }

      $('#splinks .subcontent__tabitems--item').removeClass('active');
      $(this).addClass('active');
      $('#splinks .subcontent__tabBlock--item').removeClass('active');
      $('#splinks .subcontent__tabBlock--item').eq(count).addClass('active');
    }); //電気料金のしくみタブ切り替え

    $('.js-choice0 .subcontent__tabitems--item').on('click', function () {
      var choice2flag = $(this).index();
      $('.subcontent__tabitems-choice').removeClass('active');
      $('.subcontent__tabitems--block').removeClass('active');
      $('.js-choice0 .subcontent__tabitems--item').removeClass('active');
      $(this).addClass('active');
      $('.js-choice0 .subcontent__tabBlock--item').removeClass('active');
      $('.js-choice0 .subcontent__tabBlock--item').eq(choice2flag).addClass('active');

      if (choice2flag === 0) {
        $('.js-choice1').addClass('active');
        $('.subcontent__tabitems--blockArea .subcontent__tabitems--block').eq(choice2flag).addClass('active');
      } else {
        $('.js-choice2').addClass('active');
        var tabitems = $('.js-choice2 .subcontent__tabitems .subcontent__tabitems--item');
        var tabnums = 0;

        for (var _i = 0; _i < tabitems.length; _i++) {
          if ($(tabitems[_i]).hasClass('active')) {
            tabnums = _i;
          }
        }

        $('.subcontent__tabitems--blockArea .subcontent__tabitems--block').removeClass('active');
        $('.subcontent__tabitems--blockArea .subcontent__tabitems--block').eq(tabnums).addClass('active');
      }
    });
    $('.js-choice2 .subcontent__tabitems--item').on('click', function () {
      var count = $(this).index();
      $('.js-choice2 .subcontent__tabitems--item').removeClass('active');
      $(this).addClass('active');
      $('.js-choice2 .subcontent__tabBlock--item').removeClass('active');
      $('.js-choice2 .subcontent__tabBlock--item').eq(count).addClass('active');
      $('.subcontent__tabitems--blockArea .subcontent__tabitems--block').removeClass('active');
      $('.subcontent__tabitems--blockArea .subcontent__tabitems--block').eq(count).addClass('active');
    });
    var Target = $('.is-empty');
    $(Target).on('change', function () {
      if ($(Target).val() !== '') {
        $(this).removeClass('is-empty');
      } else {
        $(this).addClass('is-empty');
      }
    }); //trimByLines('.mem__text2', '.readmore', 3);

    sliderSetting();
    slidebnrWidth('.top__bnrarea .scrolling .slide__content', '.top__bnrarea .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.turuene__member .scrolling .slide__content', '.turuene__member .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.subcontent__imgarea.slidearea .scrolling .slide__content', '.subcontent__imgarea.slidearea .scrolling .slick-dots li', [1, 1, 1, 0]);
    slidebnrWidth('.planContents .slidearea .slide__content', '.planContents__contentsArea .scrolling .slick-dots li', [1, 1, 1, 0]);
    slidebnrWidth('.newsin__slider .scrolling .slide__content', '.newsin__slider .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.ene_news .scrolling .slide__content', '.ene_news .scrolling .slick-dots li', [3, 2, 1, 0]);
  }); //リサイズ処理

  $(window).resize(function () {
    var hSize = $(window).height();
    $('.home header').height(hSize); // アドレスバーを除いたサイズを付与

    sliderSetting();
    slidebnrWidth('.top__bnrarea .scrolling .slide__content', '.top__bnrarea .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.turuene__member .scrolling .slide__content', '.turuene__member .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.subcontent__imgarea.slidearea .scrolling .slide__content', '.subcontent__imgarea.slidearea .scrolling .slick-dots li', [1, 1, 1, 0]);
    slidebnrWidth('.planContents .slidearea .slide__content', '.planContents__contentsArea .scrolling .slick-dots li', [1, 1, 1, 0]);
    slidebnrWidth('.newsin__slider .scrolling .slide__content', '.newsin__slider .scrolling .slick-dots li', [3, 2, 1, 0]);
    slidebnrWidth('.ene_news .scrolling .slide__content', '.ene_news .scrolling .slick-dots li', [3, 2, 1, 0]);
  }); //TOPの文字浮き上がり

  function topLoadMovie() {
    // 5秒後に実行
    setTimeout(function () {
      $('.catch_01').css('opacity', '1');
    }, 8000); // 10秒後に実行

    setTimeout(function () {
      $('.catch_02').css('opacity', '1');
    }, 10000);
  } //メンバーズテキストの3行表示オフ
  // function rocks() {
  //   $('.mem__text2').each(function () {
  //     const textHeight = $(this).height();
  //     const lineHeight = parseFloat($(this).css('line-height'));
  //     const lineNum = 3;
  //     const textNewHeight = lineHeight * lineNum;
  //     if (textHeight > textNewHeight) {
  //       $(this).css({
  //         height: textNewHeight,
  //         overflow: 'hidden',
  //       });
  //       $(this).find('.readmore').show();
  //       $('.readmore').click(function () {
  //         $('.readmore').hide();
  //         $('.mem__text2').css({
  //           height: textHeight,
  //           overflow: 'visible',
  //         });
  //         return false;
  //       });
  //     } else {
  //       $(this).children('.readmore').hide();
  //     }
  //   });
  // }
  // function trimByLines(text, more, lines) {
  //   const $text = $(text); //対象のテキスト
  //   const $more = $(more); //続きを読むボタン
  //   const lineNum = lines; //表示する行数
  //   let count = 0;
  //   const textHeight = $text.height(); //テキスト全文の高さ
  //   //const lineHeight = parseFloat($text.css('line-height')); //line-height
  //   //const textNewHeight = lineHeight * lineNum; //指定した行数までのテキストの高さ
  //   $text.each(function () {
  //     const lineHeight2 = parseFloat($(this).css('line-height'));
  //     const textNewHeight = lineHeight2 * lineNum;
  //     // テキストが表示制限の行数を超えたら発動
  //     if ($(this).height() > textNewHeight) {
  //       $(this).children('.readmore').css('display', 'block');
  //       $(this).css({
  //         height: textNewHeight,
  //         overflow: 'hidden',
  //       });
  //       //続きを読むボタンクリックで全文表示
  //       $more.click(function () {
  //         //$more.hide();
  //         $text.css({
  //           height: $(this).height(),
  //           overflow: 'visible',
  //         });
  //         //return false; //aタグ無効化
  //       });
  //     } else {
  //       // 指定した行数以下のテキストなら続きを読むは表示しない
  //       $more.hide();
  //     }
  //   });
  // }
  //スクロール禁止用関数]


  function noScroll(event) {
    event.preventDefault();
  } //スクロール禁止イベント


  function none_scroll() {
    document.addEventListener('touchmove', noScroll, {
      passive: false
    });
    document.addEventListener('mousewheel', noScroll, {
      passive: false
    });
  } //スクロール禁止解除イベント


  function scroll_gogo() {
    document.removeEventListener('touchmove', noScroll, {
      passive: false
    });
    document.removeEventListener('mousewheel', noScroll, {
      passive: false
    });
  } //スライドバーの長さを指定


  function slidebnrWidth(selecter, selecter2, slideItemArry) {
    var ww = $(window).width();
    var slideItemLentgh = slideItemArry;
    var nums = 0;
    if (ww >= 1122) nums = slideItemLentgh[0];
    if (ww < 1122) nums = slideItemLentgh[1];
    if (ww < 741) nums = slideItemLentgh[2];
    if (ww < 480) nums = slideItemLentgh[3];
    var slideCount = $(selecter).length;
    var slideNum = Math.floor(100 / (slideCount - nums));
    $(selecter2).css('width', "".concat(slideNum, "%"));
  }

  function sliderSetting() {
    var width = $(window).width();
    var screenwidth = (width - 1122) / 2;
    var contentwidth = 1122 + screenwidth;

    if (width > 1122) {
      $('.content__right').each(function () {
        $(this).css('width', "".concat(contentwidth));
      });
    } else {
      $('.content__right').css('width', '100%');
    }
  }

  $('.faq_anker__contents--dt').on('click', function () {
    if ($(this).parents('.faq_anker__contents').hasClass('active')) {
      $(this).parents('.faq_anker__contents').removeClass('active');
      $(this).next('.faq_anker__contents--datail').slideUp('fast');
    } else {
      $(this).parents('.faq_anker__contents').addClass('active');
      $(this).next('.faq_anker__contents--datail').slideDown('fast');
    }
  });
  $('.faq_anker__contents .clese__btn').on('click', function () {
    $(this).parents('.faq_anker__contents--datail').slideUp('fast');
    $(this).parents('.faq_anker__contents').removeClass('active');
  });
  $(window).on('scroll', function () {
    //const scrollhead = $('.scrollhead');
    //500px スクロールしたらボタン表示
    if ($(this).scrollTop() > 500) {
      $('.home .headnav').addClass('scrollhead').fadeIn(); //.show();
    } else {
      $('.home .headnav').removeClass('scrollhead');
    }
  });

  window.onload = function () {
    if (toplocation[3] === 'change') {
      $('.grecaptcha-badge').addClass('dispnone');
    } else {
      $('.grecaptcha-badge').removeClass('dispnone');
    }
  };
});